<template>
  <svg
    id="NEW"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 775 390"
    xml:space="preserve"
  >
    <title id="lockupSvgTitle">We would love to work with you!</title>
    <desc id="lockupSvgDesc">We build autenthic and impactful brands, and digital experiences, with good fun companies. That's you!</desc>
    <g>
      <text
        transform="matrix(1 0 0 1 -1.6875 53.8994)"
        class="lockuptext small lockuptext-large"
      >WE BUILD AUTHENTIC</text>
      <text
        transform="matrix(1 0 0 1 95.39 137.124)"
        class="lockuptext small lockuptext-large"
      >AND IMPACTFUL</text>
      <text
        transform="matrix(1 0 0 1 84.7693 304.5732)"
        class="lockuptext small lockuptext-large"
      >WITH GOOD, FUN</text>
      <text
        transform="matrix(1 0 0 1 108.6015 388.7989)"
        class="lockuptext small lockuptext-large"
      >COMPANIES</text>
      <text
        transform="matrix(1 0 0 1 560.0785 372.1772)"
        class="lockuptext small lockuptext-small"
      >(that’s you!)</text>
      <text
        transform="matrix(1 0 0 1 436.6075 203.7267)"
        class="lockuptext small lockuptext-small"
      >(and digital experiences)</text>
      <text
        transform="matrix(1 0 0 1 119.592 221.3491)"
        class="lockuptext small lockuptext-large"
      >BRANDS</text>
    </g>
  </svg>
</template>