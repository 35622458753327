<template>
	<svg version="1.1"
		 xmlns="http://www.w3.org/2000/svg"
		 xmlns:xlink="http://www.w3.org/1999/xlink"
		 viewBox="0 0 600 540"
		 xml:space="preserve">
		<title id="lockupSvgTitle">We would love to work with you!</title>
		<desc id="lockupSvgDesc">We build autenthic and impactful brands, and digital experiences, with good fun companies. That's you!</desc>
		<g>
			<text transform="matrix(1 0 0 1 128.6921 55.8322)"
				  class="lockuptext small lockuptext-large">
				WE BUILD
			</text>
			<text transform="matrix(1 0 0 1 98.8341 223.5542)"
				  class="lockuptext small lockuptext-large">
				IMPACTFUL
			</text>
			<text transform="matrix(1 0 0 1 78.8167 421.0204)"
				  class="lockuptext small lockuptext-large">
				WITH GOOD,
			</text>
			<text transform="matrix(1 0 0 1 2.6718 504.6804)"
				  class="lockuptext small lockuptext-large">
				FUN COMPANIES
			</text>
			<text transform="matrix(1 0 0 1 247.073 534.7476)"
				  class="lockuptext small lockuptext-small">
				(that's you!)
			</text>
			<text transform="matrix(1 0 0 1 190.4059 337.2292)"
				  class="lockuptext small lockuptext-small">
				(and digital experiences)
			</text>
			<text transform="matrix(1 0 0 1 149.8191 306.9626)"
				  class="lockuptext small lockuptext-large">
				BRANDS
			</text>
			<text transform="matrix(1 0 0 1 0.8594 139.6993)"
				  class="lockuptext small lockuptext-large">
				AUTHENTIC AND
			</text>
		</g>
	</svg>
</template>