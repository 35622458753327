<template>
	<div class="card-wrapper featured-cases">
		<div class="row">
			<div ref="cardWrapperRef"
				 class="col-xs-12">
				<div class="card-container">
					<template v-for="work in data">
						<baseCard v-if="work.linkedWork.data.attributes.preview"
								  :key="'prev-' + work.linkedWork.data.attributes.id"
								  class="card preview"
								  :style="`--details-color: ${work.linkedWork.data.attributes.pageColors.detailsColor};`"
								  :aria-label="`Check out the ${work.linkedWork.data.attributes.displayTitle} case`"
								  data-cursor-custom
								  data-cursor-text="WIP"
								  @mouseenter="mouseEnter"
								  @mouseleave="mouseLeave">
							<template v-if="work.linkedWork.data.attributes.thumbnailImage.data"
									  #imageSlot>
								<div class="image-wrapper"
									 :style="`padding-top: ${(work.linkedWork.data.attributes.thumbnailImage.data.attributes.height / work.linkedWork.data.attributes.thumbnailImage.data.attributes.width) * 100}%`">
									<clientOnly>
										<baseVideo v-if="work.linkedWork.data.attributes.videos && !isMobile"
												   :data="work.linkedWork.data.attributes.videos[0]"
												   preload
												   loop
												   autoplay
												   muted
												   playsinline
												   disable-remote-playback />
									</clientOnly>
									<basePicture v-if="work.linkedWork.data.attributes.thumbnailImage"
												 :data="work.linkedWork.data.attributes.thumbnailImage.data.attributes"
												 class="static-image"
												 :class="{ hidden: hover && !isMobile }"
												 :sizes="sizes"
												 loading="eager" />
								</div>
							</template>

							<template #contentSlot>
								<div v-if="work.linkedWork.data.attributes.casedetails?.client"
									 class="card-subtitle">
									<span class="subtitle-description">Coming soon</span>
									<span class="subtitle-content">{{ work.linkedWork.data.attributes.casedetails.client
									}}</span>
								</div>
								<h2 ref="titleRef"
									class="card-title"
									v-html="work.linkedWork.data.attributes.displayTitle" />
							</template>

							<template #bottomSlot />
						</baseCard>

						<NuxtLink v-else
								  :key="'case-' + work.linkedWork.data.attributes.id"
								  :to="`/work/${work.linkedWork.data.attributes.slug}`"
								  class="card-link"
								  :style="`--details-color: ${work.linkedWork.data.attributes.pageColors.detailsColor};`"
								  :aria-label="`Check out the ${work.linkedWork.data.attributes.displayTitle} case`"
								  data-cursor-custom
								  data-cursor-text="PEEP IT">
							<baseCard class="card"
									  @mouseenter="mouseEnter"
									  @mouseleave="mouseLeave">
								<template v-if="work.linkedWork.data.attributes.thumbnailImage.data"
										  #imageSlot>
									<div class="image-wrapper"
										 :style="`padding-top: ${(work.linkedWork.data.attributes.thumbnailImage.data.attributes.height / work.linkedWork.data.attributes.thumbnailImage.data.attributes.width) * 100}%`">
										<clientOnly>
											<baseVideo v-if="work.linkedWork.data.attributes.videos && !isMobile"
													   :data="work.linkedWork.data.attributes.videos[0]"
													   preload
													   loop
													   autoplay
													   muted
													   playsinline
													   disable-remote-playback />
										</clientOnly>
										<basePicture v-if="work.linkedWork.data.attributes.thumbnailImage"
													 :data="work.linkedWork.data.attributes.thumbnailImage.data.attributes"
													 class="static-image"
													 :class="{ hidden: hover && !isMobile }"
													 :sizes="sizes"
													 loading="eager" />
									</div>
								</template>
								<template #contentSlot>
									<div v-if="work.linkedWork.data.attributes.casedetails.client"
										 class="card-subtitle">
										<span class="subtitle-description">Project</span>
										<span class="subtitle-content">{{ work.linkedWork.data.attributes.casedetails.client
										}}</span>
									</div>
									<h2 ref="titleRef"
										class="card-title"
										v-html="work.linkedWork.data.attributes.displayTitle" />
								</template>
								<template #bottomSlot>
									<div class="arrow">
										<div class="line" />
										<arrowTip class="point" />
									</div>
								</template>
							</baseCard>
						</NuxtLink>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>



<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import arrowTip from '~/assets/images/icon-caret-right.svg'
process.client && gsap.registerPlugin(ScrollTrigger)

defineProps({
	data: {
		type: Object,
		required: true,
	},
	sizes: {
		type: String,
		required: false,
		default: '(max-width: 659px) 100vw, (max-width: 1024px) 67vw, 50vw',
	},
})

const mediaQuery = gsap.matchMedia()
const isMobile = useBreakpoint('below', 'md')

const cardWrapperRef = ref(null)
const titleRef = ref(null)

const hover = ref(false)
const triggers = {}
let $q

function mouseEnter(e) {
	const el = e.currentTarget
	!isMobile.value && el.classList.add('hovered')
}

function mouseLeave(e) {
	const el = e.currentTarget
	el.classList.remove('hovered')
}

function animRevealCases() {
	const cards = $q('.card')

	cards.forEach((card, i) => {
		triggers[`revealFeatured${i}`] = gsap.timeline({
			scrollTrigger: {
				id: `revealFeaturedCard${i}`,
				trigger: card,
				start: 'top bottom',
			}
		})
			.to(card, {
				autoAlpha: 1,
				duration: 1.5,
				ease: 'circ.out'
			})
			.from(card, {
				y: 100,
				duration: 2,
				ease: 'expo.out'
			}, '<')
			.to(titleRef.value, {
				'--font-width': 400,
				duration: 3,
				ease: 'expo.out'
			}, '<')

		mediaQuery.add('(min-width: 768px)', () => {
			triggers[`scrollSpeed${i}`] = gsap
				.timeline({
					scrollTrigger: {
						id: `scrollSpeedFeaturedCard${i}`,
						trigger: card,
						start: 'top bottom',
						end: 'center top',
						scrub: 0.5,
					}
				})
				.fromTo(card, {
					yPercent: -10 * (i % 2),
				}, {
					yPercent: () => -30 * (i % 2),
					// ease: 'power1.inOut'
				}, 0)
		})
	})
}

function initFeaturedCases() {
	$q = gsap.utils.selector(cardWrapperRef.value)
	animRevealCases()
}

onMounted(() => {
	useAnims(initFeaturedCases, triggers)
})
</script>

<style lang="scss" scoped>
.card-container {
	display: grid;
	gap: rem-calc(120 30);
	grid-template-columns: 1fr;

	@include for-devices-above(md) {
		grid-template-columns: repeat(2, 1fr);
	}

	.card-link {
		display: block;
		width: 100%;
		max-width: rem-calc(1163);
	}

	.card-wrapper {
		@include standard-margin-bottom;

		.card-right {
			display: flex;
			justify-content: flex-end;
		}
	}

	.card {
		width: 100%;
		max-width: rem-calc(1163);
		visibility: hidden;

		&.hovered {
			picture {
				opacity: 0;
			}
		}

		.image-wrapper {
			width: 100%;
			position: relative;

			video {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				height: auto;
				height: 100%;
				min-width: 100%;
				object-fit: cover;
			}

			picture {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transition: opacity 0.5s $easeOutQuint;
				will-change: opacity;

				:deep(img) {
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					height: auto;
					min-height: 100%;
					min-width: 100%;
				}
			}
		}

		:deep(.card-title) {
			text-transform: none;
			@include textH4;
			align-items: stretch;
			flex-grow: 2;
			--font-width: 100;
			margin: rem-calc(5 0 15 0);

			@include for-devices-above(sm) {}

			b {
				font-family: "SuperStrange";
				font-variation-settings: "wdth" var(--font-width, 100);
				will-change: font-variation-settings;
			}
		}

		.card-subtitle {
			color: var(--details-color, $black);
			margin: rem-calc(24px 0px 0px 0px);
			@include textBody;

			.subtitle-content {
				font-weight: bold;
			}
		}

		.arrow {
			// transition: width 0.5s $easeOutQuint;

			.line {
				background-color: var(--details-color, $black);
			}

			.point {
				color: var(--details-color, $black);
			}
		}

		&:hover {
			.arrow {
				width: calc(100% - 7px);
			}
		}
	}
}
</style>
