<template>
	<section ref="heroLockupRef"
			 class="hero-lockup"
			 :style="{ padding: headerPadding }">
		<div ref="lockupContainerRef"
			 class="lockup-container container-fluid">
			<ContentLockupLarge v-if="lockupSvg === 'lockupLarge'"
								class="lockup" />
			<ContentLockupMedium v-if="lockupSvg === 'lockupMedium'"
								 class="lockup" />
			<ContentLockupSmall v-if="lockupSvg === 'lockupSmall'"
								class="lockup" />
		</div>
	</section>
</template>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { useMetaStore } from '~~/stores/metaStore'
import { storeToRefs } from 'pinia'

if (process.client) gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const { $bus } = useNuxtApp()
const metaStore = useMetaStore()

const heroLockupRef = ref(null)
const lockupContainerRef = ref(null)

const { headerHeight, getDelay } = storeToRefs(metaStore)
const lockupSvg = ref('lockupMedium')
const triggers = {}

const headerPadding = computed(() => {
	if (headerHeight.value) {
		return (headerHeight.value + (headerHeight.value / 4) + 'px ') + '0px ' + (headerHeight.value + 'px ') + '0px'
	} else {
		return 455 + 'px'
	}
})

function deviceOrientation() {
	if (!process.client) return false
	if (window.innerWidth <= 768 && window.innerWidth < window.innerHeight) { lockupSvg.value = 'lockupSmall' }
	if (window.innerWidth > 768 && window.innerWidth < 1513) { lockupSvg.value = 'lockupMedium' }
	if (window.innerWidth > 1513) { lockupSvg.value = 'lockupLarge' }
}

function revealLockup() {
	gsap.timeline()
		.from(lockupContainerRef.value, {
			y: 20,
			scale: 0.97,
			duration: 1,
			delay: getDelay.value / 1000,
			ease: 'sine.out',
		})
		.to(lockupContainerRef.value, {
			autoAlpha: 1,
			duration: 1.5,
			delay: getDelay.value / 1000,
			ease: 'circ.out',
		}, '<')
}

function initComponent() {
	deviceOrientation()
	revealLockup()
}

onMounted(() => {
	useAnims(initComponent, triggers)

	$bus.$on('window-resized', deviceOrientation)
})

onBeforeUnmount(() => {
	$bus.$off('window-resized', deviceOrientation)
})
</script>

<style lang="scss" scoped>
.hero-lockup {
	overflow: hidden;
	position: relative;
	will-change: transform, padding;
	transition: padding 0.5s ease-in-out;

	.lockup-container {
		position: relative;
		background-color: var(--lockup-background-color, $white);
		color: var(--lockup-foreground-color, $white);
		visibility: hidden;
		will-change: transform, opacity;

		*::selection {
			background: $white;
			color: $black;
		}

		img::selection,
		svg::selection {
			background: $white;
			color: $black;
		}

		.lockup {
			width: 100%;
			color: red;
			@include universal-side-padding;

			:deep(.lockuptext) {
				font-weight: $semiBold;
				color: var(--foreground-color, $black);

				&.large {
					&.lockuptext-small {
						font-size: rem-calc(24);
					}

					&.lockuptext-large {
						font-size: rem-calc(100);
					}
				}

				&.small {
					&.lockuptext-small {
						font-size: rem-calc(20);
					}

					&.lockuptext-large {
						font-size: rem-calc(74);
					}
				}
			}
		}
	}
}
</style>