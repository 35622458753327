<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1513 337.7"
    xml:space="preserve"
  >
    <title id="lockupSvgTitle">We would love to work with you!</title>
    <desc id="lockupSvgDesc">We build autenthic and impactful brands, and digital experiences, with good fun companies. That's you!</desc>
    <g>
      <text
        transform="matrix(1 0 0 1 110.4638 71.3047)"
        class="lockuptext large lockuptext-large"
      >WE BUILD AUTHENTIC AND</text>
      <text
        transform="matrix(1 0 0 1 -5.9999 198.9418)"
        class="lockuptext large lockuptext-large"
      >IMPACTFUL BRANDS</text>
      <text
        transform="matrix(1 0 0 1 983.1983 174.8494)"
        class="lockuptext large lockuptext-small"
      >(and digital experiences)</text>
      <text
        transform="matrix(1 0 0 1 1258.8062 198.9418)"
        class="lockuptext large lockuptext-large"
      >WITH</text>
      <text
        transform="matrix(1 0 0 1 110.4638 326.1182)"
        class="lockuptext large lockuptext-large"
      >GOOD, FUN COMPANIES</text>
      <text
        transform="matrix(1 0 0 1 1274.9639 302.1962)"
        class="lockuptext large lockuptext-small"
      >(that’s you!)</text>
    </g>
  </svg>
</template>