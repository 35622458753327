<template>
	<div v-if="!pending && apiPageRes"
		 class="outer-page">
		<div ref="innerPageRef"
			 class="inner-page">
			<contentHeroLockup />

			<div class="container-fluid">
				<div class="row">
					<div class="col-xs-12">
						<contentTwoUpFeaturedCases :data="pageContent.featuredCases" />
					</div>
				</div>
			</div>

			<TheFlexibleContent v-for="content in pageContent.flexibleContent"
								:key="content.id"
								:data="content" />
			<FlexibleCalltoaction class="cta-bottom" />
		</div>
	</div>
</template>

<script setup>
import { useMetaStore } from '~~/stores/metaStore'

const config = useRuntimeConfig()
const metaStore = useMetaStore()
const { $bus } = useNuxtApp()
const url = '/'

const { data: apiPageRes, pending } = await useFetch(config.public.strapiBase + '/api/home?populate=deep,6')
const pageContent = computed(() => { if (apiPageRes.value) { return apiPageRes.value.data.attributes } })

const seoMeta = useAllSeoMeta(pageContent.value, { ...config, url })
process.server ? useServerSeoMeta(seoMeta) : useSeoMeta(seoMeta)

const innerPageRef = ref(null)

function initComponent() {
	metaStore.setActivePageColors(pageContent.value.pageColors)

	$bus.$emit('page-mounted')
}

onMounted(() => {
	$bus.$emit('header-set-height')

	useAnims(initComponent)
	useCssAnims(innerPageRef.value)
})
</script>

<style lang="scss" scoped>
.inner-page {
	padding-top: 0;
}

.request-full-portfolio {
	@include universal-side-padding;
	align-content: flex-end;
	text-align: right;
	overflow-x: hidden;
	margin-top: rem-calc(-100) * 0.5;

	@include for-devices-above(md) {
		margin-top: rem-calc(-140)* 0.5;
	}

	@include for-devices-above(xl) {
		margin-top: rem-calc(-200)* 0.5;
	}

	.button-safe-wrap {
		margin-top: calc((3rem * 1));
		margin-bottom: calc((3rem * 1));
	}
}
</style>